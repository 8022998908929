import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { Input } from '../components/Core'

import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgWorkCover from '../assets/image/jpg/details/ultraspeaking-details.jpg'
import persona1 from '../assets/image/jpg/details/persona-1.jpg'
import persona2 from '../assets/image/jpg/details/persona-2.jpg'
import persona3 from '../assets/image/jpg/details/persona-3.jpg'
import cjmCurrent from '../assets/image/jpg/details/customer-journey-map-current-state.jpg'
import cjmFuture from '../assets/image/jpg/details/customer-journey-map-future-state.jpg'
import wireframes1 from '../assets/image/jpg/details/wireframes-1.jpg'
import wireframes2 from '../assets/image/jpg/details/wireframes-2.jpg'
import wireframes3 from '../assets/image/jpg/details/wireframes-3.jpg'
import wireframes4 from '../assets/image/jpg/details/wireframes-4.jpg'
import wireframes5 from '../assets/image/jpg/details/wireframes-5.jpg'
import microphone1 from '../assets/image/jpg/details/microphone-1.jpg'
import imgS1 from '../assets/image/jpg/details/details-6.jpg'
import imgS2 from '../assets/image/jpg/details/details-5.jpg'
import imgS3 from '../assets/image/jpg/details/details-4.jpg'
import imgS4 from '../assets/image/jpg/details/details-3.jpg'
import imgS5 from '../assets/image/jpg/details/details-8.jpg'
import imgS6 from '../assets/image/jpg/details/details-7.jpg'
import { device } from '../utils'

const WorkSingle = () => {
  const [password, setPassword] = React.useState('')
  const [displayPost, setDisplayPost] = React.useState(false)

  return (
    <>
      <PageWrapper>
        {!displayPost && (
          <Container
            fluid
            className="px-sm-5"
            css={`
              margin-top: 92px;
            `}
          >
            <div className="mt-4">
              <input
                type="password"
                placeholder="Password"
                required
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                onClick={() =>
                  password === 'designlabrobert' && setDisplayPost(true)
                }
              >
                Submit
              </button>
            </div>
          </Container>
        )}
        {displayPost && (
          <>
            <Container
              fluid
              className="px-sm-5"
              css={`
                margin-top: 92px;
              `}
            >
              <img src={imgWorkCover} alt="" className="img-fluid w-100" />
            </Container>
            <div className="mt-lg-5 pt-lg-5">
              <Container>
                <Row>
                  <Col lg="8">
                    <Row>
                      <Col lg="3">
                        <Text variant="tag" color="darkShade">
                          Role:
                        </Text>
                        <Text variant="tag">UX/UI Designer</Text>
                      </Col>
                      <Col lg="3">
                        <Text variant="tag" color="darkShade">
                          Duration:
                        </Text>
                        <Text variant="tag">4 weeks</Text>
                      </Col>
                    </Row>
                    <Title variant="secSm" className="my-4">
                      Rethinking Ultraspeaking's platform from the ground up.{' '}
                    </Title>
                    <Text
                      variant="p"
                      className="mt-lg-5"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      Ultraspeaking is a service-based company offering a
                      cohort-based course to improve public speaking and
                      communication skills. Because of the breadth of reasons an
                      individual may have for wanting to improve their
                      communication skills, I used a simple heuristic:{' '}
                      <strong>“what are people willing to pay for?”</strong>
                    </Text>
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                        margin-top: 20px;
                      `}
                    >
                      Since Ultraspeaking is an operating business, I ran a
                      survey with 150 of its most recent students who paid
                      upwards of $1,000 for 5 weeks of classes. For the vast
                      majority of students, the "job" they hired Ultraspeaking
                      for, and the reason they paid for the classes boiled down
                      to{' '}
                      <strong>
                        communication being critical to helping them advance
                        their career.
                      </strong>
                    </Text>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="mt-lg-5">
              <Container>
                <Title variant="secSm" className="my-4">
                  Empathize
                </Title>
              </Container>
              <Container className="mt-lg-5">
                <Row>
                  <Col lg="3" className="mb-4 mb-lg-0">
                    <Text variant="tag">Surveys Conducted</Text>
                    <Title variant="cardBig" className="mt-3">
                      150
                    </Title>
                  </Col>
                  <Col lg="3" className="mb-4 mb-lg-0">
                    <Text variant="tag">Survey Response Rate</Text>
                    <Title variant="cardBig" className="mt-3">
                      >70%
                    </Title>
                  </Col>
                  <Col lg="3" className="mb-4 mb-lg-0">
                    <Text variant="tag">Personas Identified</Text>
                    <Title variant="cardBig" className="mt-3">
                      9
                    </Title>
                  </Col>
                </Row>
              </Container>
              <Container className="mt-lg-5">
                <Row>
                  <Col lg="8">
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      The surveys had >70% response rate, and I identified 9
                      personas, and 3 core personas: Chet “Locked-in Striver”
                      Driver, Cindy “The Executive” Zhang, and Oluchi “Founder”
                      Jelani.
                    </Text>
                  </Col>
                </Row>
              </Container>
              <Container className="mt-lg-5 pt-3">
                <Row>
                  <Col xs="6" className="mb-5">
                    <img src={persona1} alt="" className="img-fluid w-100" />
                  </Col>
                  <Col lg="4" className="pl-lg-4">
                    <Row>
                      <Col lg="12" className="pl-lg-4">
                        <img
                          src={persona2}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </Col>
                      <Col lg="12" className="pl-lg-4">
                        <img
                          src={persona3}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <Container className="mt-lg-4">
                <Row>
                  <Col lg="8">
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      A plethora of communication companies exist, so I
                      conducted a needs-based competitor analysis to figure out
                      exactly why users preferred Ultraspeaking’s offering to
                      incumbents. At the end of the day, it boiled down to
                      Ultraspeaking’s focus on{' '}
                      <strong>
                        flexibility and robustness of communication skills
                      </strong>
                      . Our competitors may teach someone to become incredibly
                      good at prepared presentations, but there would be no
                      transfer between that person’s ability to speak in front
                      of a crowd and their ability to captivate an audience at
                      an impromptu dinner party.
                    </Text>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="mt-lg-5">
              <Container>
                <Title variant="secSm" className="my-4">
                  Define
                </Title>
              </Container>
              <Container className="mt-lg-5">
                <Row>
                  <Col lg="8">
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      To get a clearer sense of where the product opportunities
                      lay, I created a current state Customer Journey Map to
                      track how a user behaves and feels as they perform their
                      key task flow while interacting with Ultraspeaking today.
                    </Text>
                  </Col>
                </Row>
              </Container>
              <Container className="mt-lg-5 pt-3">
                <Row>
                  <Col xs="12" className="mb-5">
                    <img src={cjmCurrent} alt="" className="img-fluid w-100" />
                  </Col>
                  <Col xs="11" className="mb-5">
                    <img src={cjmFuture} alt="" className="img-fluid w-100" />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg="8">
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      I then extrapolated a reimagined ideal future state
                      customer journey map using the personas, Value Proposition
                      Canvas, and interviews with Ultraspeaking’s founders. The
                      new flow imagined a world where the business model and
                      user journey were built around getting a student to a
                      desired speaking ability based on their current speaking
                      ability, and how much time and money they're willing to
                      spend. Based on this I also created a sitemap for the
                      redesign.
                    </Text>
                  </Col>
                </Row>
              </Container>
              <Container className="mt-lg-5">
                <Title variant="cardBig" className="mb-5 pb-lg-4">
                  Key Findings
                </Title>
                <Row>
                  <Col lg="6" className="mb-5 pr-lg-5">
                    <Title variant="cardBig" className="mb-4">
                      01. Activation Issue
                    </Title>
                    <Text variant="p">
                      Ultraspeaking’s platform loses users before they see
                      value.
                    </Text>
                  </Col>
                  <Col lg="6" className="mb-5 pl-lg-5">
                    <Title variant="cardBig" className="mb-4">
                      02. Value Preview
                    </Title>
                    <Text variant="p">
                      Students want to know what the outcome of committing to
                      Ultraspeaking looks like, and what it’ll take to get
                      there.
                    </Text>
                  </Col>
                  <Col lg="6" className="mb-5 pr-lg-5">
                    <Title variant="cardBig" className="mb-4">
                      03. Human Interactions
                    </Title>
                    <Text variant="p">
                      Human interactions through coaching and community are
                      Ultraspeaking’s biggest asset.
                    </Text>
                  </Col>
                  <Col lg="6" className="mb-5 pl-lg-5">
                    <Title variant="cardBig" className="mb-4">
                      04. Real World Application
                    </Title>
                    <Text variant="p">
                      Students want to transition their Ultraspeaking practice
                      to real-world situations.
                    </Text>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <Title variant="cardBig" className="mb-4">
                      05. Progress Tracking
                    </Title>
                    <Text variant="p">
                      Students want to visualize the progress they’ve made so
                      far.
                    </Text>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="mt-lg-5">
              <Container>
                <Title variant="secSm" className="my-4">
                  Design
                </Title>
              </Container>
              <Container>
                <Row>
                  <Col lg="8">
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      I decided to focus on the first domino of Ultraspeaking’s
                      platform—the validation phase—since this is the stage
                      where Ultraspeaking can most easily verify whether it’s on
                      the right track or not, and because of the activation
                      issues identified in the user research. This phase is a
                      2-week long free trial period in which users form their
                      opinions about whether the Ultraspeaking platform is for
                      them or not. At the end of the trial period, users decide
                      whether or not to pay for the platform. Because the
                      redesign focuses on the beginning of a user’s journey, it
                      allows Ultraspeaking to be nimble since they can run
                      experiments and see outcomes quickly. The validation phase
                      is also the ultimate test of whether or not Ultraspeaking
                      is on track or not—users have to put their money where
                      their mouth is!
                    </Text>
                  </Col>
                </Row>
              </Container>
              <Container className="mt-lg-5 pt-3">
                <Row>
                  <Col xs="12" className="mb-5">
                    <img src={wireframes3} alt="" className="img-fluid w-100" />
                  </Col>
                  <Col xs="12" className="mb-5">
                    <img src={wireframes4} alt="" className="img-fluid w-100" />
                  </Col>
                  <Col xs="12" className="mb-5">
                    <img src={wireframes5} alt="" className="img-fluid w-100" />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="mt-lg-5">
              <Container>
                <Title variant="secSm" className="my-4">
                  Test
                </Title>
              </Container>
              <Container className="mt-lg-5">
                <Row>
                  <Col lg="3" className="mb-4 mb-lg-0">
                    <Text variant="tag">Usability Tests Conducted</Text>
                    <Title variant="cardBig" className="mt-3">
                      8
                    </Title>
                  </Col>
                  <Col lg="3" className="mb-4 mb-lg-0">
                    <Text variant="tag">Test Completion Rate</Text>
                    <Title variant="cardBig" className="mt-3">
                      91%
                    </Title>
                  </Col>
                  <Col lg="3" className="mb-4 mb-lg-0">
                    <Text variant="tag">Error Free Rate</Text>
                    <Title variant="cardBig" className="mt-3">
                      92%
                    </Title>
                  </Col>
                </Row>
              </Container>
              <Container className="mt-lg-5">
                <Row>
                  <Col lg="8">
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      The design was then usability tested on 8 people to find
                      if there were any key moments of confusion, and how
                      quickly people could learn to use the platform. I ran the
                      usability tests in two batches (four users each time) so
                      that I could iterate on the design and see if the tweaks
                      fixed issues. The onboarding card seemed to provide some
                      initial confusion, but after some tweaks, those issues
                      appear to have cleared up by the second batch of usability
                      tests.
                    </Text>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="mt-lg-5">
              <Container>
                <Title variant="secSm" className="my-4">
                  Conclusion
                </Title>
              </Container>
              <Container>
                <Row>
                  <Col lg="8">
                    <Text
                      variant="p"
                      css={`
                        max-width: 750px;
                      `}
                    >
                      Because of Ultraspeaking’s unique stage in time—a company
                      transitioning from primarily service-based to one that’s
                      product-focused—the project provided a unique challenge of
                      trying to identify and navigate the path between balancing
                      open-ended business needs with unknown customer needs. The
                      preliminary results from the usability tests and a
                      stakeholder presentation have been promising. Test
                      subjects could clearly see the value in the redesign, and
                      the stakeholder presentation has led to updates in the
                      company’s strategy for the coming few quarters. The next
                      steps are to break down the redesign into smaller testable
                      experiments and to iterate our way to a successful
                      communication training platform while using the project
                      deliverables as a map.
                    </Text>
                  </Col>
                </Row>
              </Container>
            </div>
            <Section mt="24%" bg="dark">
              <Container>
                <Row
                  css={`
                    margin-top: -40%;
                  `}
                >
                  <Col xs="12" className="mb-5">
                    <img src={microphone1} alt="" className="img-fluid w-100" />
                  </Col>
                </Row>

                <div className="text-center mt-lg-5">
                  <Text variant="tag" className="mb-1" color="lightShade">
                    Next Project
                  </Text>
                  <Link to="portfolio-details">
                    <Button
                      arrowRight
                      className="border-0 bg-transparent shadow-none text-capitalize py-3"
                      css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                    >
                      Replace
                    </Button>
                  </Link>
                </div>
              </Container>
            </Section>
            {/* <Box py={4}>
          <Contact />
        </Box> */}
          </>
        )}
      </PageWrapper>
    </>
  )
}
export default WorkSingle
